<template>
  <v-container fluid>
      <v-row>
          <v-col cols="12">
              <p v-t="'vvtProcessEditor.risk.blacklist.templateSelection.p1'" />
              <p v-t="'vvtProcessEditor.risk.blacklist.templateSelection.p2'" />
          </v-col>
      </v-row>
      <v-row>
          <v-col cols="12" lg="6">
              <v-autocomplete
                  v-model="templateBlacklistSelection"
                  :items="templateBlacklistSelectionItems"
                  attach
                  chips
                  label="Blacklist"
                  multiple
                  clearable
                  deletable-chips
                  prepend-icon="mdi-clipboard-list"
                  :disabled="disabled"
              ></v-autocomplete>
          </v-col>
          <v-col cols="12" lg="6">
              <v-autocomplete
                  v-model="templateWhitelistSelection"
                  :items="templateWhitelistSelectionItems"
                  attach
                  chips
                  label="Whitelist"
                  multiple
                  clearable
                  deletable-chips
                  prepend-icon="mdi-clipboard-list-outline"
                  :disabled="disabled"
              ></v-autocomplete>
          </v-col>
      </v-row>
  </v-container>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    props: {
        disabled: {
            type: Boolean,
            default: false
        }
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/ProcessEditor/en.json'),
            de: require('@/locales/vvt/ProcessEditor/de.json'),
        },
    },
    computed: {
        ...mapGetters({
            get: 'processingActivityModel/getProperty',
            euCountries: 'countries/getEuCountries',
            findCountryByCode: 'countries/findByCountryCode'
        }),
        germanStateItems () {
          return [
                {
                    value: this.countryIdGermany + '_BW',
                    text: this.$t('countryStates.de.bw')
                },
                {
                    value: this.countryIdGermany + '_BY',
                    text: this.$t('countryStates.de.by')
                },
                {
                    value: this.countryIdGermany + '_BE',
                    text: this.$t('countryStates.de.be')
                },
                {
                    value: this.countryIdGermany + '_BB',
                    text: this.$t('countryStates.de.bb')
                },
                {
                    value: this.countryIdGermany + '_HB',
                    text: this.$t('countryStates.de.hb')
                },
                {
                    value: this.countryIdGermany + '_HH',
                    text: this.$t('countryStates.de.hh')
                },
                {
                    value: this.countryIdGermany + '_HE',
                    text: this.$t('countryStates.de.he')
                },
                {
                    value: this.countryIdGermany + '_MV',
                    text: this.$t('countryStates.de.mv')
                },
                {
                    value: this.countryIdGermany + '_NI',
                    text: this.$t('countryStates.de.ni')
                },
                {
                    value: this.countryIdGermany + '_NW',
                    text: this.$t('countryStates.de.nw')
                },
                {
                    value: this.countryIdGermany + '_RP',
                    text: this.$t('countryStates.de.rp')
                },
                {
                    value: this.countryIdGermany + '_SL',
                    text: this.$t('countryStates.de.sl')
                },
                {
                    value: this.countryIdGermany + '_SN',
                    text: this.$t('countryStates.de.sn')
                },
                {
                    value: this.countryIdGermany + '_ST',
                    text: this.$t('countryStates.de.st')
                },
                {
                    value: this.countryIdGermany + '_SH',
                    text: this.$t('countryStates.de.sh')
                },
                {
                    value: this.countryIdGermany + '_TH',
                    text: this.$t('countryStates.de.th')
                },
            ];
        },
        countryIdGermany () {
          return this.findCountryByCode('DE').id;
        },
        templateBlacklistSelection: {
            get () {
                return this.getBlacklistSelection('blacklist');
            },
            set (val) {
                this.setBlacklistSelection('blacklist', val);
            }
        },
        templateWhitelistSelection: {
            get () {
                return this.getBlacklistSelection('whitelist');
            },
            set (val) {
                this.setBlacklistSelection('whitelist', val);
            }
        },
        templateBlackAndWhitelistSelectionItems () {
            let arr = [];
            arr.push({header: this.$t('countryStates.de.title')});
            this.germanStateItems.forEach(s => arr.push(s))
            arr.push({divider: true, country: 'germany'});
            arr.push({header: this.$t('countries')});
            this.euCountries.forEach(x => {
                arr.push({
                    value: x.id,
                    countryCode: x.countryCode,
                    text: x.countryName
                });
            });
            return arr;
        },
        templateBlacklistSelectionItems () {
            return this.templateBlackAndWhitelistSelectionItems.filter(x => !this.templateWhitelistSelection.includes(x.value));
        },
        templateWhitelistSelectionItems () {
            return this.templateBlackAndWhitelistSelectionItems.filter(x => !this.templateBlacklistSelection.includes(x.value));
        },
    },
    methods: {
        ...mapActions({
            set: 'processingActivityModel/setProperty',
        }),
        getBlacklistSelection(type) {
            let stored = this.get('dpiaBlacklistEntries');
            if (!stored || !stored.length) {
                return [];
            }
            let byType = (type === 'blacklist') ? stored.filter(x => !x.whiteList) : stored.filter(x => x.whiteList);
            let returnValue = byType.map(x => {
                if (!x.state || parseInt(x.state) === 0) {
                    return parseInt(x.countryId);
                } else {
                    return x.state;
                }
            })
            return returnValue;
        },
        setBlacklistSelection(type, val) {
            let blacklistValues = (type === 'blacklist') ? val : this.getBlacklistSelection('blacklist');
            let whitelistValues = (type === 'whitelist') ? val : this.getBlacklistSelection('whitelist');
            let setValue = [];
            blacklistValues.forEach(x => {
              setValue.push(this.transformValue(x, false));
            });
            whitelistValues.forEach(x => {
              setValue.push(this.transformValue(x, true));
            });
            this.set({ property: 'dpiaBlacklistEntries', data: setValue });
        },
        transformValue (value, isWhiteList) {
            if (Number.isInteger(value)) {
                  return {
                    countryId: value,
                    whiteList: isWhiteList,
                    state: 0
                  };
              } else {
                  let values = value.split('_');
                  return {
                    countryId: parseInt(values[0]),
                    whiteList: isWhiteList,
                    state: value
                  };
              }
        }
    }
}
</script>