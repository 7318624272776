<template>
    <div>
        <DpiaBlacklistEntries v-if="template" :disabled="!canEditExtended" />
        <v-card-text v-if="get('parentTemplateId')">
            <v-alert type="info" outlined v-if="!template && !countryCode" icon="mdi-information-outline" class="mb-3">
                {{ $t('vvtProcessEditor.risk.blacklist.noCountry') }}
            </v-alert>
            <v-row v-if="countryCode" class="fill-height">
                <BlacklistCountry
                    :countryCode="countryCode"
                />
            </v-row>
        </v-card-text>
        <v-card-title
            class="text-subtitle-1"
            primary-title
        >
        <span v-if="get('parentTemplateId')">{{ $t('vvtProcessEditor.risk.blacklist.table.title') }}</span>
        <v-spacer v-if="get('parentTemplateId')"></v-spacer>
        <v-text-field
            v-model="blacklistTableSearch"
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
            item-key="value"
        ></v-text-field>
        </v-card-title>
        <v-card-text>
            <v-data-table
                :headers="headers"
                :items="filteredBlacklistItems"
                class="data-table--blacklist data-table--row-cursor-pointer"
                sort-by="country"
                :sort-desc="false"
                group-by="country"
                show-group-by2
                disable-pagination
                hide-default-footer
                ref="blacklistTable"
                calculate-widths
                :search="blacklistTableSearch"
                :no-results-text="$t('noData')"
                :custom-filter="filterBlacklistTable"
                single-expand
                show-expand
                :expanded.sync="blacklistTableExpanded"
                @click:row="onClickBlacklistEntry"
            >
                <template v-slot:top>
                    <v-btn
                        v-if="!template"
                        text
                        @click="toggleDisplayOtherCountries()"
                        class="btn--default-transform mb-3"
                        color="primary"
                    ><v-icon>{{ displayOtherCountries ? 'mdi-toggle-switch' : 'mdi-toggle-switch-off' }}</v-icon>{{ $t('vvtProcessEditor.risk.blacklist.table.toggleCountries') }}</v-btn>
                </template>
                <template v-slot:[`group.header`]="{ group, groupBy, isOpen, toggle, headers }">
                    <td :colspan="headers.length" class="white pa-0">
                        <v-btn v-if="groupBy.includes('country')" block text x-large tile @click="toggle" class="px-3 pl-4 text-left btn--default-transform btn--justify-content-start">
                            <v-icon>
                                {{ isOpen ? 'mdi-menu-down' : 'mdi-menu-right' }}
                            </v-icon>
                            <v-avatar size="24" class="ml-2 mr-3">
                            <img
                                :src="`${$publicPath}images/countries/${group.toLowerCase()}.svg`"
                                :alt="getCountryName(group)"
                            >
                            </v-avatar>
                            {{ getCountryName(group) }}
                        </v-btn>
                    </td>
                </template>
                <template v-slot:[`item.data-table-expand`]="{isExpanded}">
                    <v-icon class="ml-2">
                        {{ isExpanded ? 'mdi-menu-down' : 'mdi-menu-right' }}
                    </v-icon>
                </template>
                <template v-slot:[`item.description`]="{ item, value }">
                    <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on">
                                {{ item.whiteList ? 'mdi-clipboard-list-outline' : 'mdi-clipboard-list' }}
                            </v-icon>
                        </template>
                        <span v-if="item.whiteList" v-t="'vvtProcessEditor.risk.blacklist.table.whitelist'" />
                        <span v-else v-t="'vvtProcessEditor.risk.blacklist.table.blacklist'" />
                    </v-tooltip>
                    {{ $htmlSnippet(value, 220) }}
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td class="pa-0" :colspan="headers.length">
                        <div>
                            <v-alert
                                v-if="item.country === 'DE'"
                                text
                                dense
                                color="grey"
                                tile
                                >
                                <span class="subtitle-2">{{ $t('countryStates.affected') }}:</span> <span class="font-weight-thin subtitle-2">{{ getCountryStates(item.country, item.countryStates) }}</span>
                                <template v-slot:prepend>
                                    <v-icon color="grey" class="pl-8 pr-3">mdi-map-marker-radius</v-icon>
                                </template>
                                </v-alert>
                            <div class="px-3 py-1 pl-14" v-html="$nl2br(item.description)" />
                        </div>
                    </td>
                </template>
            </v-data-table>
        </v-card-text>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BlacklistCountry from './BlacklistCountry.vue';
import DpiaBlacklistEntries from './DpiaBlacklistEntries.vue';

export default {
    name: 'VvtRiskBlacklistPanelInformation',
    components: {
        BlacklistCountry,
        DpiaBlacklistEntries,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        countryCode: {
            type: String,
            default: null,
        },
        template: {
            type: Boolean,
            default: false,
        },
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/ProcessEditor/en.json'),
            de: require('@/locales/vvt/ProcessEditor/de.json'),
        },
    },
    data() {
        return {
            displayOtherCountries: false,
            blacklistTableSearch: '',
            blacklistTableExpanded: [],
        };
    },
    computed: {
        ...mapGetters({
            findCountryByCode: 'countries/findByCountryCode',
            blacklistItems: 'blacklist/getItems',
            get: 'processingActivityModel/getProperty',
            getCompanyGroupSettings: 'companyGroupSettings/get'
        }),
        canEdit () {
            return this.get('canEdit');
        },
        canEditExtended () {
            return this.get('canEditExtended');
        },
        headers () {
            return [
                { 
                    text: this.$t('vvtProcessEditor.risk.blacklist.table.description'),
                    value: 'description',
                    groupable: false
                }
            ]
        },
        filteredBlacklistItems () {
            if (!this.countryCode || this.template || this.displayOtherCountries) {
                return this.blacklistItems;
            }
            return this.blacklistItems.filter(x => x.country.toUpperCase() === this.countryCode.toUpperCase());
        }
    },
    methods: {
        onClickBlacklistEntry (val, props) {
            props.expand(!props.isExpanded);
        },
        getCountryName(val) {
            let country = this.findCountryByCode(val);
            return country && country.countryName ? country.countryName : ''
        },
        getCountryStates (countryCode, countryStates) {
            if (!countryStates.length) {
                return this.$t('all');
            }
            return countryStates.map(x => {
                return this.$t('countryStates.' + countryCode.toLowerCase() + '.' + x.state.toLowerCase());
            }).join(', ');
        },
        closeBlacklistCountries () {
            let table = this.$refs.blacklistTable;
            if (table) {
                let keys = Object.keys(table.$vnode.componentInstance.openCache);
                keys.forEach(x => {
                    table.$vnode.componentInstance.openCache[x] = false;
                })
            }
        },
        toggleDisplayOtherCountries () {
            this.displayOtherCountries = !this.displayOtherCountries;
            this.$nextTick(() => {
                this.closeBlacklistCountries();
            })
        },
        filterBlacklistTable (value, search, item) {
            return value !== null &&
            search !== null &&
            typeof value === 'string' &&
            (
                value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
                || (
                    item.countryStates.length 
                    && this.getCountryStates(item.country, item.countryStates).toLowerCase().includes(search.toLowerCase()))
            )
        }
    },
    mounted () {
        this.closeBlacklistCountries();
    }
};
</script>
