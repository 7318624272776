<template>
    <v-col cols="12" sm="6" md="4" v-if="currentCountryBlacklistState.length || currentCountryWhitelistState.length">
        <v-card :loading="$wait.is('checkBlacklistEntries')" flat>
            <v-card-title class="text-subtitle-1">
                <v-avatar size="24" class="ml-1 mr-3">
                <img
                    :src="`${$publicPath}images/countries/${countryCode.toLowerCase()}.svg`"
                    :alt="countryName"
                >
                </v-avatar>{{ countryName }}
            </v-card-title>
            <template v-if="currentCountryBlacklistState.length">
                <v-card-text v-for="(bl, i) in currentCountryBlacklistState" :key="'b' + i">
                    <template v-if="bl.state && bl.state !== '0'">
                        <v-icon class="pr-2">mdi-clipboard-list</v-icon>{{ $t('vvtProcessEditor.risk.blacklist.stateText.onBlackListInCountryState', { stateName: getStateName(bl.state), countryName: countryName }) }}
                    </template>
                    <template v-else>
                        <v-icon class="pr-2">mdi-clipboard-list</v-icon>{{ $t('vvtProcessEditor.risk.blacklist.stateText.onBlackListInCountry', { countryName }) }}
                    </template>
                </v-card-text>
                <v-alert
                    text
                    dense
                    color="grey"
                    tile
                    icon="mdi-information-variant"
                    >
                        <span class="font-weight-thin subtitle-2">{{ $t('vvtProcessEditor.risk.blacklist.stateText.noteBlacklist') }}</span>
                    </v-alert>
            </template>
            <template v-if="currentCountryWhitelistState.length">
                <v-card-text v-for="(bl, i) in currentCountryWhitelistState" :key="'w' + i">
                    <template v-if="bl.state && bl.state !== '0'">
                        <v-icon class="pr-2">mdi-clipboard-list-outline</v-icon>{{ $t('vvtProcessEditor.risk.blacklist.stateText.onWhiteListInCountryState', { stateName: getStateName(bl.state), countryName: countryName }) }}
                    </template>
                    <template v-else>
                        <v-icon class="pr-2">mdi-clipboard-list-outline</v-icon>{{ $t('vvtProcessEditor.risk.blacklist.stateText.onWhiteListInCountry', { countryName }) }}
                    </template>
                </v-card-text>
                <v-alert
                    text
                    dense
                    color="grey"
                    tile
                    icon="mdi-information-variant"
                    >
                        <span class="font-weight-thin subtitle-2">{{ $t('vvtProcessEditor.risk.blacklist.stateText.noteWhitelist') }}</span>
                    </v-alert>
            </template>
        </v-card>
    </v-col>
</template>

<script>
import { mapGetters } from 'vuex';
import http from '@/utils/axios';
export default {
    name: 'VvtRiskBlacklistPanelCountry',
    props: {
        countryCode: String,
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/ProcessEditor/en.json'),
            de: require('@/locales/vvt/ProcessEditor/de.json'),
        },
    },
    data() {
        return {
            blacklistEntries: []
        };
    },
    computed: {
        ...mapGetters({
            findCountryByCode: 'countries/findByCountryCode',
            get: 'processingActivityModel/getProperty',
        }),
        country() {
            return this.findCountryByCode(this.countryCode);
        },
        countryName() {
            return this.country.countryName;
        },
        parentTemplateId () {
            return this.get('parentTemplateId') ? this.get('parentTemplateId') : null;
        },
        currentCountryBlacklistState () {
            return this.blacklistEntries ? this.blacklistEntries.filter(x => !x.whiteList && x.countryId === this.country.id) : [];
        },
        currentCountryWhitelistState () {
            return this.blacklistEntries ? this.blacklistEntries.filter(x => x.whiteList && x.countryId === this.country.id) : [];
        }
    },
    methods: {
        fetchBlacklistEntriesFromTemplate () {
            if (this.parentTemplateId) {
                this.$wait.start('checkBlacklistEntries');
                http(`/api/blacklist/record/${this.parentTemplateId}`)
                .then(response => {
                    if (!response.data.error) {
                        this.blacklistEntries = response.data;
                    } 
                    this.$wait.end('checkBlacklistEntries');
                })
                .catch(() => {
                    this.$wait.end('checkBlacklistEntries');
                })
            }
        },
        getStateName (str) {
            let arrStr = str.split('_');
            return arrStr[1] ? this.$t('countryStates.de.' + arrStr[1].toLowerCase()) : null;
        }
    },
    mounted() {
        this.fetchBlacklistEntriesFromTemplate();
    }
};
</script>
